import {
  useAccountCustomFieldGroups,
  useAccountCustomFieldGroupsLookup,
  useAccountCustomFieldLookup,
  useRunbookRunbookType
} from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { DuplicateRunbooksModal } from 'main/components/shared/runbook-duplicate/duplicate-runbooks-modal'
import { usePermittedProjects } from 'main/services/hooks/use-permitted-projects'
import { ActiveAccountModel, ActiveRunbookModel } from 'main/data-access'
import { CustomFieldUserModel } from 'main/data-access/models/custom-field-user-model'

export const RunbookDuplicateModalWrapper = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })

  const runbook = ActiveRunbookModel.get()
  const templateType = runbook.template_type
  const runbookType = useRunbookRunbookType()
  const customFieldsLookup = useAccountCustomFieldLookup()
  const customFieldUsers = CustomFieldUserModel.getAll()
  const customFieldGroupsLookup = useAccountCustomFieldGroupsLookup()
  const customFieldGroups = useAccountCustomFieldGroups()
  const accountId = ActiveAccountModel.get('id')
  const { data } = usePermittedProjects({ accountId, enabled: templateType !== 'snippet' })
  const hasPermissionOnExistingProject = data?.projects.some(p => p.id === runbook.project_id)

  const customFieldProps = {
    customFieldsLookup: customFieldsLookup.fields,
    customFieldUsers,
    customFieldGroupsLookup,
    customFieldGroups
  }

  const isRunbookTemplateNotApproved = runbook?.is_template && runbook.template_status !== 'template_approved'
  const isRunbookTypeDisabled = !!runbookType?.disabled

  return (
    <DuplicateRunbooksModal
      runbook={runbook}
      runbookType={runbookType}
      open
      closeModal={closeModal}
      runbookIds={null}
      templateType={templateType === 'off' ? undefined : templateType}
      customFieldProps={customFieldProps}
      errorMessage={isRunbookTypeDisabled ? [t('disabledRunbookTypeError')] : undefined}
      warningMessage={isRunbookTemplateNotApproved ? [t('unapprovedTemplateWarning')] : undefined}
      context="single"
      hasPermissionOnExistingProject={hasPermissionOnExistingProject}
      projects={data?.projects}
    />
  )
}
