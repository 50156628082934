import { memo } from 'react'

import { CheckboxGroup } from '@cutover/react-ui'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { useGlobalConfigProperty, useStageFilter } from 'main/recoil/data-access'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { TaskStage, useLanguage } from 'main/services/hooks'
import { ActiveRunbookVersionModel } from 'main/data-access'

type RunbookStageFilterValueType = RunbookFilterType['stage']

export const StageGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter.runbook' })
  // TODO: replace with RunModel.get()
  const { run } = ActiveRunbookVersionModel.get()
  const taskStages = useGlobalConfigProperty({ attribute: 'taskStageTypes' }) as TaskStage[]
  const [runbookStages, setRunbookStages] = useStageFilter()

  return run ? (
    <FilterAccordionPanel label={t('stage')} applied={!!runbookStages?.length}>
      <CheckboxGroup
        plain
        a11yTitle={t('stage')}
        options={taskStages.map(stage => ({
          value: stage.id,
          label: stage.label
        }))}
        value={runbookStages}
        onChange={(event: any) => event && setRunbookStages(event.value as RunbookStageFilterValueType)}
      />
    </FilterAccordionPanel>
  ) : null
})
