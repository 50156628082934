import { memo, Ref, SyntheticEvent, useCallback } from 'react'

import { AvatarSubject, DependencyType, TaskItem, useNotify } from '@cutover/react-ui'
import {
  useCanDisplayCreateAfterFromIcon,
  useCanProgressTask,
  useCanStartTaskWhenStartable,
  useCanUpdateTask,
  useDropAssignment,
  useHasStartedSuccessors,
  useRunbookComponentsStateLookup,
  useStream,
  useTaskCommentsCount,
  useTaskListTask,
  useTaskListTaskUsers,
  useTaskProgression,
  useTaskProgressionState
} from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { TaskItemCreateToggle } from './task-item-create'
import { useTaskListItemProps } from './task-list-item-props'
import { useTaskListItemIntegrationStatus } from './use-task-list-item-integration-status'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  CurrentRunbookVersionModel,
  RunbookTeamsModel,
  RunbookViewModel,
  TaskTypeModel
} from 'main/data-access'

type TaskContentProps = {
  id: number
  critical?: boolean
  float?: number
  previousTaskId?: number
  nextTaskId?: number
  isEditing?: boolean
}

// TODO: remove when transaction updates have been made to recoil data updating
export const TaskListItem = memo(({ id, ...props }: TaskContentProps) => {
  const task = useTaskListTask(id)
  if (!task) return null
  return <TaskListItemInner id={id} {...props} />
})

const TaskListItemInner = (props: TaskContentProps) => {
  const { isEditing, id, critical, float, previousTaskId, nextTaskId } = props
  const first = !previousTaskId

  const toggleActionsMenu = RunbookViewModel.onAction('taskMenu:toggle:actions')
  const toggleDependenciesMenu = RunbookViewModel.onAction('taskMenu:toggle:dependencies')
  const { open: menuOpen, taskId, type } = RunbookViewModel.get('menu')
  const { active: activeModal } = RunbookViewModel.get('modal')
  const isUpdating = RunbookViewModel.get('loadingIds')[id] || false
  const isActioning = activeModal?.type === 'task-action' ? id === activeModal.id : undefined

  const selectedIdToggle = RunbookViewModel.onAction('selectedIds:toggle')
  const selectedIdsRemoveAll = RunbookViewModel.onAction('selectedIds:removeAll')
  const selected = RunbookViewModel.get('selectedIds').includes(id)

  const notify = useNotify()

  const taskProgressionState = useTaskProgressionState(id)
  const togglePeoplePanel = useToggleRightPanel(
    'user-team-details-edit',
    (openPanel, openingPanel) => openPanel.userOrTeam?.id === openingPanel.userOrTeam?.id
  )
  const toggleCommentsPanel = useToggleRightPanel('runbook-comments')
  const toggleTaskEditPanel = useToggleRightPanel('task-edit')
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const modalOpen = RunbookViewModel.onAction('modal:open')
  const toggleTaskCreate = RunbookViewModel.onAction('taskCreate:toggle')

  const runbookVersion = ActiveRunbookVersionModel.get()
  const task = useTaskListTask(id)
  const stream = useStream(task.stream_id)
  const runbookCurrentVersion = CurrentRunbookVersionModel.get()
  const runbook = ActiveRunbookModel.get()
  const userCanUpdate = ActiveRunbookModel.can('update')
  const commentsCountState = useTaskCommentsCount(task.internal_id)
  const parentStream = useStream(stream.parent_id || 0)
  const previousTask = useTaskListTask(previousTaskId || id)
  const previousTaskType = TaskTypeModel.get(previousTask?.task_type_id)
  const runbookComponentLookup = useRunbookComponentsStateLookup()
  const teams = RunbookTeamsModel.getAllBy({ taskId: id })
  const taskType = TaskTypeModel.get(task.task_type_id)
  const users = useTaskListTaskUsers(id)
  const getTaskShowCreateAfterFromIcon = useCanDisplayCreateAfterFromIcon()
  const getTaskCanProgress = useCanProgressTask()
  const getCanUpdateTask = useCanUpdateTask()
  const getTaskHasStartedSuccessors = useHasStartedSuccessors()
  const canStartWhenStartable = useCanStartTaskWhenStartable()

  const canProgressTask = getTaskCanProgress(id)
  const canUpdateTask = getCanUpdateTask(id)

  const taskItemProps = useTaskListItemProps({
    showCreateAfter: getTaskShowCreateAfterFromIcon(id),
    canUpdate: userCanUpdate,
    critical,
    commentsCountState,
    float,
    first,
    iconDisabled: !canStartWhenStartable(id),
    nextTaskId,
    parentStream,
    previousTask,
    previousTaskType,
    run: runbookVersion.run,
    runbookComponent: task.runbook_component_id ? runbookComponentLookup[task.runbook_component_id] : undefined,
    runbookCurrentVersion,
    runbookVersion,
    runbook,
    stream,
    task,
    taskType,
    teams,
    users
  })
  const { resolveProgressionModalRecoilCallback, startOrFinishTask, onSkipTasks } = useTaskProgression()
  const { handleDropAssign, isLoadingAvatar, isDropPermitted } = useDropAssignment({ canUpdateTask, teams, users })

  const { integrationActionItem, integrationOptions, integrationStatusProps } = useTaskListItemIntegrationStatus(
    task
  ) ?? {
    integrationActionItem: undefined,
    integrationOptions: {},
    integrationStatusProps: undefined
  }

  const handleOptionsClick = useCallback(
    (triggerRef: Ref<HTMLElement>) => {
      toggleActionsMenu({ triggerRef, task, integrationActionItem, integrationOptions })
    },
    [toggleActionsMenu, task, integrationActionItem, integrationOptions]
  )

  const handleDependenciesClick = useCallback(
    (triggerRef: Ref<HTMLElement>, dependencyType: DependencyType) => {
      toggleDependenciesMenu({ triggerRef, dependencyType, task })
    },
    [toggleDependenciesMenu, task]
  )

  const handleClickTaskItem = useCallback(() => {
    toggleTaskEditPanel({ taskId: id })
  }, [id])

  const handleBulkTaskSelection = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      const nativeEvent = e.nativeEvent as MouseEvent
      selectedIdToggle(id, nativeEvent.shiftKey)
    },
    [selectedIdToggle, id]
  )

  const onAdd = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleTaskCreate({ predecessor: id })
    },
    [toggleTaskCreate, id]
  )

  const hasHoverAddIcon = taskItemProps.iconProps.hoverIcon === 'add'
  const clickable = hasHoverAddIcon || canProgressTask || taskProgressionState?.override === 'fixed-start'

  const handleClickTaskIcon = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation()
      // We need to do this check here because we still want to show the add icon if all other checks to show it pass
      // so we can show specific messaging for this limitation vs just disabling click.
      if (hasHoverAddIcon) {
        const hasStartedSuccessors = getTaskHasStartedSuccessors(id)
        if (hasStartedSuccessors) {
          notify.warning(t('startedSuccessorWarning.message'), { title: t('startedSuccessorWarning.title') })
        } else onAdd(e)
        return
      }
      const nextModal = await resolveProgressionModalRecoilCallback(id)
      return nextModal ? modalOpen(nextModal) : startOrFinishTask(id)
    },
    [resolveProgressionModalRecoilCallback, id, hasHoverAddIcon, getTaskHasStartedSuccessors]
  )

  const handleCommentsClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    toggleCommentsPanel({ taskId: id, taskInternalId: task.internal_id })
  }, [])

  const handleSkipClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      selectedIdsRemoveAll()
      onSkipTasks([id])
    },
    [selectedIdsRemoveAll, id]
  )

  const handleClickAvatar = useCallback(
    (e: SyntheticEvent, subject?: AvatarSubject) => {
      e.stopPropagation()
      togglePeoplePanel({ userOrTeam: subject })
    },
    [togglePeoplePanel]
  )

  const handleErrorClick = useCallback(() => {}, [])

  const handleDrop = (e: React.DragEvent) => {
    if (!isDropPermitted(e)) {
      return false
    }

    handleDropAssign({ taskId: task.id, data: JSON.parse(e.dataTransfer.getData('text')) })
  }

  return (
    <>
      <TaskItem
        {...taskItemProps}
        isOptionsMenuOpen={taskId === id && menuOpen && type === 'options'}
        draggable={false}
        highlight={isEditing}
        isLoadingTeams={isLoadingAvatar}
        onChangeSelect={handleBulkTaskSelection}
        onClick={handleClickTaskItem}
        onClickAvatar={handleClickAvatar}
        onClickComments={handleCommentsClick}
        onClickError={handleErrorClick}
        onClickOptions={handleOptionsClick}
        onClickDependencies={handleDependenciesClick}
        onClickSkip={handleSkipClick}
        onDrop={handleDrop}
        selected={selected}
        editable={canUpdateTask}
        updating={isUpdating}
        iconProps={{
          ...taskItemProps.iconProps,
          isLoading: isUpdating || isActioning,
          onClick: clickable ? handleClickTaskIcon : undefined
        }}
        labelCommentsButton={t('comments', { count: commentsCountState || task.comments_count })}
        labelEarly={t('taskStartDiffText.early')}
        labelLate={t('taskStartDiffText.late')}
        labelMenuButton={t('moreOptions')}
        labelSelectCheckbox={t('selectTask')}
        labelSkipButton={t('skipTaskButtonLabel')}
        labelStarted={taskItemProps.started ? t('taskStartDiffText.forecastToStart') : t('taskStartDiffText.started')}
        integrationStatusProps={integrationStatusProps}
      />
      <TaskItemCreateToggle predecessorId={id} />
    </>
  )
}
