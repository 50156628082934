import { keyBy, mapValues } from 'lodash'
import { atom, AtomEffect, DefaultValue, selector, selectorFamily } from 'recoil'
import { produce } from 'immer'

import { accountMetaState } from '../account/account'
import { appliedFilterState, nowDateAtom } from 'main/recoil/shared/filters'
import { applyFilterPipeline } from 'main/services/tasks/filtering/filter-map'
import { buildFilterContext, TaskFilterContext } from 'main/services/tasks/filtering/filter-context'
import { CustomNavigationEventDetail } from 'main/recoil/shared/recoil-sync/navigation-event-emitter'
import { getRunbookId, getRunbookVersionId } from 'main/recoil/shared/nav-utils'
import { getTasks, TaskListResponseType } from 'main/services/queries/use-tasks'
import { streamsInternalIdLookupState, streamState } from '../runbook-version/streams'
import { runbookVersionMetaState } from '../runbook-version/runbook-version'
import { runbookComponentsInternalIdLookup } from '../runbook-version/runbook-components'
import { sort } from 'main/services/tasks/sort'
import { TaskListTask } from 'main/services/queries/types'
import { currentUserIdState } from 'main/recoil/current-user'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { teamsStateLookup } from '../runbook-version/teams'

export type FieldValuesByTaskAndCustomFieldIdLookup = {
  [task_id: number]: {
    [custom_field_id: number]: {
      value: string | null
      field_option_id: number | null
    }[]
  }
}

const syncTasksResponseEffect: AtomEffect<TaskListResponseType> = ({ setSelf, resetSelf }) => {
  const getInitialTasks = async () => {
    const runbookId = getRunbookId()
    const runbookVersionId = getRunbookVersionId()

    if (runbookId && runbookVersionId) {
      const data = await getTasks(runbookId, runbookVersionId)
      setSelf(data)
    } else {
      return new DefaultValue()
    }
  }

  getInitialTasks()

  const handlePathChange = async (event: CustomEvent<CustomNavigationEventDetail>) => {
    const { pathname, previousPathname } = event.detail
    const previousRunbookId = getRunbookId(previousPathname)
    const runbookId = getRunbookId(pathname) as string
    // will need to manually deal with when both are 'current_runbook'
    const previousRunbookVersionId = getRunbookVersionId(previousPathname)
    const runbookVersionId = getRunbookVersionId(pathname) as string

    if (!runbookId || !runbookVersionId) return

    if (
      runbookId &&
      previousRunbookId === runbookId &&
      runbookVersionId &&
      previousRunbookVersionId === runbookVersionId
    ) {
      return
    }

    resetSelf()

    if (runbookId && runbookVersionId) {
      const request = getTasks(runbookId, runbookVersionId)
      const data = await request

      setSelf(data)
    }
  }

  const handleRefresh = async (event: any) => {
    if (event.detail.type === 'tasks') {
      getInitialTasks()
    }
  }

  window.addEventListener('pathnamechanged', handlePathChange as any)
  window.addEventListener('refresh-data-store', handleRefresh as any)

  return () => {
    window.removeEventListener('pathnamechanged', handlePathChange as any)
    window.removeEventListener('refresh-data-store', handleRefresh as any)
  }
}

// this is what is updated automatically with the url sync and provides the initial value
// for all tasks to build the lookup
export const taskListResponseState_INTERNAL = atom<TaskListResponseType>({
  key: 'tasks:response',
  // confirm needed and doc why (add properties to objects during crutical path evaluation)
  dangerouslyAllowMutability: true,
  effects: [syncTasksResponseEffect]
})

export const taskListState = selector({
  key: 'tasks:tasks',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { tasks } = get(taskListResponseState_INTERNAL)
    return tasks
  }
})

export const taskListMetaState = selector({
  key: 'tasks:meta',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { meta } = get(taskListResponseState_INTERNAL)
    return meta
  }
})

export const taskListLookupState = selector<Record<string, TaskListTask>>({
  key: 'tasks:lookup',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const tasksResponse = get(taskListResponseState_INTERNAL)
    const { tasks } = tasksResponse

    return keyBy(tasks, 'id')
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return
    }

    const prevTaskResp = get(taskListResponseState_INTERNAL)

    set(
      taskListResponseState_INTERNAL,
      produce(prevTaskResp, draft => {
        draft.tasks = Object.values(newValue)
      })
    )
  }
})

export const taskListInternalIdLookupState = selector<Record<string, TaskListTask>>({
  key: 'tasks:internal-id:lookup',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { tasks } = get(taskListResponseState_INTERNAL)

    return keyBy(tasks, 'internal_id')
  }
})

// TODO: needs a context selector that conditionally builds a context object based on the applied filters.
// Needs to be updated and replaced with fully conditionally logical context to optimize filter matching as part of
// https://cutover.atlassian.net/browse/CFE-1138. Also consolidate the filter context creating between here and the
// buildFilterContext function in main/services/tasks/filtering/filter-map.ts
const taskListFilterContext = selectorFamily<TaskFilterContext, RunbookFilterType>({
  key: 'tasks:filter-context',
  get:
    filters =>
    ({ get }) => {
      // TODO: can we get this from the tasks list state?
      const { tasks } = get(taskListResponseState_INTERNAL)

      const { task_types, custom_fields } = get(accountMetaState)
      const { runbook_teams, users } = get(runbookVersionMetaState)
      const currentUserId = get(currentUserIdState)
      const streamInternalIdLookup = get(streamsInternalIdLookupState)
      const now = get(nowDateAtom)
      const fieldValuesLookup = get(taskListCustomFieldsValuesLookup)
      const taskLookup = get(taskListLookupState)
      const runbookComponentInternalIdLookup = get(runbookComponentsInternalIdLookup)

      return buildFilterContext(filters, {
        now,
        field_values_lookup: fieldValuesLookup,
        tasks,
        task_types,
        custom_fields,
        // @ts-ignore shouldn't ever be undefined...
        current_user: currentUserId,
        runbook_teams: runbook_teams,
        runbook_users: users, // currently not used, but should this be?
        streamInternalIdLookup,
        taskLookup,
        runbookComponentInternalIdLookup
      })
    }
})

const taskListCustomFieldsValuesLookup = selector({
  key: 'tasks:custom-fields:field-values:lookup',
  get: ({ get }) => {
    const tasks = get(taskListResponseState_INTERNAL).tasks
    const fieldValuesLookup: FieldValuesByTaskAndCustomFieldIdLookup = {}

    tasks.forEach(task => {
      const fieldValues = task.field_values
      fieldValuesLookup[task.id] = {}

      fieldValues.forEach(fieldValue => {
        const { custom_field_id, value, field_option_id } = fieldValue
        const currentValue = fieldValuesLookup[task.id][custom_field_id] ?? []
        fieldValuesLookup[task.id][custom_field_id] = [...currentValue, { value, field_option_id }]
      })
    })

    return fieldValuesLookup
  }
})

export const filteredTaskListDataState = selector<[number[], TaskFilterContext]>({
  key: 'tasks:filter-data',
  get: ({ get }) => {
    const filters = get(appliedFilterState)
    const filterContext = get(taskListFilterContext(filters))
    const { tasks } = get(taskListResponseState_INTERNAL)

    const filteredTasks = applyFilterPipeline([...tasks], filters, filterContext)
    const sortedTasks = sort(filteredTasks)

    return [sortedTasks.map(t => t.id), filterContext]
  }
})

export const filteredTasksState = selector({
  key: 'tasks:filtered',
  get: ({ get }) => {
    const filters = get(appliedFilterState)
    const hasFilters = !!Object.keys(filters ?? {}).length

    if (!hasFilters) {
      return get(taskListResponseState_INTERNAL).tasks
    }

    const [filteredIds] = get(filteredTaskListDataState)
    const taskListLookup = get(taskListLookupState)

    return filteredIds.map(id => taskListLookup[id])
  }
})

export const taskListCountState = selector<{ total: number; filtered: number }>({
  key: 'tasks:count',
  get: ({ get }) => {
    const tasks = get(taskListResponseState_INTERNAL).tasks
    const [filteredIds] = get(filteredTaskListDataState)

    return {
      total: tasks.length,
      filtered: filteredIds.length
    }
  }
})

export const taskListTaskState = selectorFamily<TaskListTask, number>({
  key: 'task:id',
  get:
    id =>
    ({ get }) => {
      const tasks = get(taskListLookupState)
      return tasks[id]
    },
  set:
    id =>
    ({ set }, newValue: any) => {
      if (newValue instanceof DefaultValue) return

      if (newValue === null)
        return set(taskListLookupState, prev => {
          const p = { ...prev }
          delete p[id]
          return { ...p }
        })

      set(taskListLookupState, prev => ({ ...prev, [id]: { ...prev[id], ...newValue } }))
    }
})

export const taskEditInitialDataState = selectorFamily({
  key: 'task-edit-panel:initial-data',
  get:
    (id: number | null) =>
    ({ get }) => {
      if (!id) {
        return null
      }

      const task = get(taskListTaskState(id))
      return task
    }
})

export const streamEditInitialDataState = selectorFamily({
  key: 'stream-edit-panel:initial-data',
  get:
    (id: number | null) =>
    ({ get }) => {
      if (id === null) {
        return null
      }

      return get(streamState({ id }))
    }
})

export const teamIdToTaskCountRecord = selector<Record<number, number>>({
  key: 'teams:tasks:count',
  get: ({ get }) => {
    const teamsLookup = get(teamsStateLookup)
    const filteredTasks = get(filteredTasksState)

    return mapValues(teamsLookup, team => {
      return filteredTasks.filter(task => task.runbook_team_ids?.includes(team.id)).length
    })
  }
})
